import React, { useState } from 'react';

import { Route, Switch } from 'react-router-dom';

import SalesList from './SalesList';
import SimulatorForm from './SimulatorForm';
import PayerForm from './PayerForm';
import PageNotFound from '../../components/PageNotFound';

import { Receipt } from './Receipt';

import { Step, Responsive } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  isAbleToSimulatorForm,
  isAbleToPayerForm,
  isAbleToReceiptForm
} from '../../services/sales';

import styled from 'styled-components';

const ContentContainer = styled.div`
  max-width: ${props => props.windowWidth}px;
`;

const Steps = (props) => {
  const { pathname } = props.location;
  const { simulator, payer, sale } = props;

  const [contentContainerWidth, setContentContainerWidth] = useState(window.innerWidth);

  const handleOnUpdate = (e, { width }) => setContentContainerWidth(width);

  const handleSimulatorStepEnableBehavior = () =>
    (isAbleToSimulatorForm(sale) ? { disabled: false } : { disabled: true });

  const handlePayerStepEnableBehavior = () =>
    (isAbleToPayerForm(simulator, sale) ? { disabled: false } : { disabled: true });

  const handleReceiptStepEnableBehavior = () =>
    (isAbleToReceiptForm(simulator, payer, sale) ? { disabled: false } : { disabled: true });

  const handleStepBehavior = (pathname, route, handleClick) =>
    (pathname === route ? { active: true } : { onClick: handleClick });

  const Steps = () => (
    <>
      <Step {...handleSimulatorStepEnableBehavior()}
        {...handleStepBehavior(pathname, '/admin/sales/simulator', () => props.history.push('/admin/sales/simulator'))}>
        <Step.Content>
          <Step.Title>Simulador</Step.Title>
          <Step.Description>Realize a simulação do valor original dos serviços</Step.Description>
        </Step.Content>
      </Step>
      <Step {...handlePayerStepEnableBehavior()}
        {...handleStepBehavior(pathname, '/admin/sales/payer', () => props.history.push('/admin/sales/payer'))}>
        <Step.Content>
          <Step.Title>Pagador</Step.Title>
          <Step.Description>Informe os dados do pagador</Step.Description>
        </Step.Content>
      </Step>
      <Step {...handleReceiptStepEnableBehavior()}
        {...handleStepBehavior(pathname, '/admin/sales/receipt', () => props.history.push('/admin/sales/receipt'))}>
        <Step.Content>
          <Step.Title>Recibo da Venda</Step.Title>
          <Step.Description>Imprima o recibo da venda realizada</Step.Description>
        </Step.Content>
      </Step>
    </>
  );

  return (
    <>
      <Responsive fireOnMount onUpdate={handleOnUpdate} minWidth={Responsive.onlyComputer.minWidth}>
        <ContentContainer windowWidth={contentContainerWidth}>
          <Responsive as={Step.Group} fluid size='small'>
            <Steps />
          </Responsive>
        </ContentContainer>
      </Responsive>
      <Responsive fireOnMount maxWidth={Responsive.onlyComputer.minWidth}>
        <Responsive as={Step.Group} vertical fluid size='small'>
          <Steps />
        </Responsive>
      </Responsive>
    </>
  );
}

const SalesForm = (props) => {
  const { pathname } = props.location;
  const { simulator, payer, sale } = props;

  let isAbleToForm = true;
  let pushTo = null;

  const validPages = ['simulator', 'payer', 'receipt'];
  const pageIsValid = (page) => validPages.filter(validPage => validPage === page).length > 0;

  return (
    <>
      { (pathname !== '/admin/sales' && pageIsValid(pathname.split('/').pop())) && ( <Steps {...props} /> ) }
      <Route component={({ match }) => {
        if (pathname === `${match.url}/payer` && ! isAbleToPayerForm(simulator, sale)) {
          pushTo = `${match.url}/simulator`;
        }

        if (pathname === `${match.url}/receipt` && ! isAbleToReceiptForm(simulator, payer, sale)) {
          pushTo = `${match.url}/payer`;
        }

        if (null !== pushTo) {
          isAbleToForm = false;
          props.history.push(pushTo);
        }

        return (
          <>
            { isAbleToForm && (
              <>
                <Switch>
                  <Route exact path={`${match.url}`} component={SalesList} />
                  <Route path={`${match.url}/simulator`} component={SimulatorForm} />
                  <Route path={`${match.url}/payer`} component={PayerForm} />
                  <Route path={`${match.url}/receipt`} component={Receipt} />
                  <Route component={PageNotFound} />
                </Switch>
              </>
            ) }
          </>
        )
      }}/>
    </>
  )
};

const mapStateToProps = state => ({
  simulator: state.simulator,
  payer: state.payer,
  sale: state.sale
});

export default connect(mapStateToProps)(SalesForm);
