import React from 'react';
import { Divider, Grid, Header } from 'semantic-ui-react';

import AddressForm from '../sales/AddressForm';

const AddressFields = (props) => {
  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column>
          <Header as='h3'>
            Endereço do Estabelecimento
          </Header>
          <Divider clearing />
        </Grid.Column>
      </Grid.Row>
      <AddressForm {...props} />
    </Grid>
  );
};

export default AddressFields;
