import React from 'react';

import { Grid } from 'semantic-ui-react';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ProtectedRoute from './components/ProtectedRoute';
import Login from './pages/login/Login';
import Dashboard from './pages/dashboard/Dashboard';
import Credentiation from './pages/credentiation/Credentiation';
import DocumentManager from './pages/adherence-monitoring/DocumentManager';

const App = ({ history }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{ process.env.REACT_APP_TITLE }</title>
      </Helmet>
      <Grid.Row>
        <Grid.Column>
          <Route exact path="/" component={Login} />
          <ProtectedRoute path="/admin" component={Dashboard} />
          <Route exact path="/credentiation" component={Credentiation} />
          <Route path="/adherence-monitoring" component={DocumentManager} />
        </Grid.Column>
      </Grid.Row>
    </>
  );
}

export default App;
