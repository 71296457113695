export const login = {
  state: {
    email: "",
    password: "",
    loading: false,
  },

  reducers: {},
};

export const user = {
  state: {
    owner: "",
    role: "",
  },
  reducers: {
    updateUser(state, payload) {
      return { ...state, ...payload };
    },
  },
};

export const simulator = {
  state: {},
  reducers: {
    updateSimulator(state, payload) {
      return { ...state, ...payload };
    },
  },
};

export const payer = {
  state: {},
  reducers: {
    updatePayer(state, payload) {
      return payload;
    },
  },
};

export const loading = {
  state: false,
  reducers: {
    isLoading(state, payload) {
      return payload;
    },
  },
};

export const loadingModal = {
  state: false,
  reducers: {
    isLoading(state, payload) {
      return payload;
    },
  },
};

export const sidebarIsVisible = {
  state: false,
  reducers: {
    openSidebar(state, payload) {
      return true;
    },
    closeSidebar(state, payload) {
      return false;
    },
    toggleSidebar(state) {
      return !state;
    },
  },
};

export const payment_types = {
  state: [],
  reducers: {
    updatePaymentTypes(state, payload) {
      return payload;
    },
  },
};

export const sale = {
  state: {},
  reducers: {
    createSale(state, payload) {
      return payload;
    },
    clearSale(state, payload) {
      return {};
    },
  },
};

export const owner = {
  state: {
    key: "",
    value: "",
    text: "",
    termsOfService: "",
  },
  reducers: {
    updateOwner(state, payload) {
      return payload;
    },
  },
};
