import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import * as Storage from '../../services/storage';

import {
  maskMoney,
} from '../../services/masks';

moment.locale('pt-br');

const Wrapper = styled.div`
  color: #000;
  text-transform: uppercase;
  line-height: 15px;
  font-size: 12px;
  font-weight: bold;
`;

const CenteredHeader = styled.h4`
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin: 0;
`;

const RightAlignedSpan = styled.span`
  float: right;
`

const Section = styled.div`
  width: 100%;
  margin: 0;
  font-size: 12px;
  font-weight: bold;

  .title {
    font-weight: bold;
  }

  .resource-name {
    font-weight: bold;
  }
`;

const Divider = styled.hr`
  border: 1px dashed #000;
`;
class TaxCoupon extends Component {

  render() {
    const owner_name = Storage.get('owner_name');
    const owner_document = Storage.get('owner_document');
    const owner_phone = Storage.get('owner_phone');

    const sale = this.props.data

    const { payer, payee, payment_type,split_rules } = sale;

    let parsed_date;

    try {
        parsed_date = moment(sale.created_at).tz('America/Sao_Paulo');
      } catch (e) {}
    

    return (
      <Wrapper>
        <Section>
          <CenteredHeader>Recibo de Transação da Operação</CenteredHeader>
        </Section>

        <Section>
          <div>
            <strong>{`${parsed_date.format('DD/MM/YYYY')} às ${parsed_date.format('HH:MM')}`}</strong>
          </div>
          <CenteredHeader>Empresa</CenteredHeader>
          <div>
          <strong>Nome:{' '}{owner_name}</strong>
          </div>
          <div>
            <strong>CPF/ CNPJ:{' '}{owner_document}</strong>
          </div>
          <div>
            <strong>Telefone:{' '}{owner_phone}</strong>
          </div>
          <div>
            <strong>Endereço:</strong> 
          </div>
        </Section>

        <Section>
          <CenteredHeader>Cliente</CenteredHeader>
          <div>
            <strong>Nome:{' '}{payer.name}</strong>
          </div>
          <div>
            <strong>CPF/CNPJ:{' '}{payer.cpf}</strong>
          </div>
          <div>
            <strong>Email:{' '}{payer.email}</strong>
          </div>
          <div>
            <strong>Telefone:{' '}{payer.phone}</strong>
          </div>
        </Section>

        <Section>
          <CenteredHeader>Vendedor</CenteredHeader>
          <div>
            <strong>Nome:{' '}{payee.name}</strong>
          </div>
          <div>
            <strong>CNPJ:{' '}{payee.document}</strong>
          </div>
        </Section>
             
        <Section>
          <div>
            <CenteredHeader>Data de Realização</CenteredHeader>
            <strong>{parsed_date && `${parsed_date.format('DD/MM/YYYY')} às ${parsed_date.format('HH:mm:ss')}`}</strong>
          </div>
          
        </Section>

        <Section>
          <div>
            <CenteredHeader>Dados de Pagamento</CenteredHeader>
            <div>
            <strong>{`${payment_type.description} de: `}<RightAlignedSpan>{`R$ ${maskMoney(sale.installment_amount, true)}`}</RightAlignedSpan></strong>
            </div>
          </div>
          
        </Section>
       
        <Section>
        <div>
            <strong>Descrição: <RightAlignedSpan>{sale.description}</RightAlignedSpan></strong>
          </div>
          <div>
            <strong>Preço: <RightAlignedSpan>{`R$ ${maskMoney(sale.original_amount, true)}`}</RightAlignedSpan></strong>
          </div>
          
        </Section>
        <br></br>

        <Section>
          <CenteredHeader>Descrição dos valores</CenteredHeader>
          <div>
            <strong>Total destinado ao cartório: <RightAlignedSpan>{`R$ ${maskMoney(split_rules.original_amount, true)}`}</RightAlignedSpan></strong>
          </div>
          <div>
            <strong>Total a pagar parcelado: <RightAlignedSpan>{`R$ ${maskMoney(split_rules.total_with_interest, true)}`}</RightAlignedSpan></strong>
          </div>
          <div>
            <strong>Custo financeiro da operação: <RightAlignedSpan>{`R$ ${maskMoney(split_rules.parcela_amount, true)}`}</RightAlignedSpan></strong>
          </div>
        </Section>
        <br></br><br></br>
        <Section>
          <div>
          <strong>O titular do cartão confirma a transação realizada e renuncia, de forma irretratável, ao direito de cancelar ou contestar a operação junto à operadora de cartão de crédito.</strong>
          </div>
          <br></br><br></br>
          <div>
          <strong>Assinatura do Cliente: ________________________________________</strong>
          </div>
          
        </Section>
        <Divider />
      </Wrapper>
    );
  }
}

TaxCoupon.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TaxCoupon;
