import React from 'react';

import { Route, Switch } from 'react-router-dom';
import { Grid, Responsive } from 'semantic-ui-react';

import SalesForm from '../sales/SalesForm';
import PageNotFound from '../../components/PageNotFound';
import QuickSimulatorForm from '../simulator/QuickSimulatorForm';
import Statement from '../statement/Statement';
import Receivables from '../receivables/Receivables';

import Sidebar from '../../containers/Sidebar';
import Navbar from '../../containers/Navbar';

import styled from 'styled-components';

import { connect } from 'react-redux';

const ColumnContainer = styled(Grid.Column)`
  padding: 2.5em;
  margin-top: 1em;
`;

const Dashboard = (props) => {
  const { dispatch } = props;

  const handleOnUpdate = (e, { width }) => {
    dispatch.sidebarIsVisible.closeSidebar();
  };

  return (
    <>
      <Responsive onUpdate={handleOnUpdate} as={Sidebar} {...props}>
        <Grid.Row>
          <Grid.Column>
            <Navbar {...props} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <ColumnContainer>
            <Route exact path='/' />
            <Route component={({ match }) =>
              <>
                <Switch>
                  <Route path={`${match.url}/sales`} component={SalesForm} />
                  <Route path={`${match.url}/simulator`} component={QuickSimulatorForm} />
                  <Route path={`${match.url}/statement`} component={Statement} />
                  <Route path={`${match.url}/receivables`} component={Receivables} />
                  <Route component={PageNotFound} />
                </Switch>
              </>
            }/>
          </ColumnContainer>
        </Grid.Row>
      </Responsive>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarIsVisible: state.sidebarIsVisible,
  user: state.user,
});

export default connect(mapStateToProps)(Dashboard);
