import React, { useState } from 'react';

import styled from 'styled-components';

import { Grid } from 'semantic-ui-react';
import { ErrorMessage, Field } from 'formik';
import { states } from '../../services/states';

import Input from '../../components/Input';
import Dropdown from '../../components/Dropdown';

import { get as requestGet } from '../../services/request';

const Error = styled(ErrorMessage)`
  padding: 0.5em 0 0 0;
  color: red;
`;

const AddressForm = (props) => {
  const { setFieldValue } = props;
  const [isSearching, setIsSearching] = useState(false);

  const handleZipcodeSearch = async (e) => {
    const zipcode = e.target.value;

    if (zipcode.length === 8) {
      try {
        setIsSearching(true);

        const { data } = await requestGet(`https://cors-anywhere.herokuapp.com/https://viacep.com.br/ws/${zipcode}/json`);

        if (data.erro) {
          throw new Error('Zipcode not found');
        }
        const { logradouro: street, complemento: complement, bairro: district, localidade: city, uf: state } = data;

        setFieldValue('address[street]', street);
        setFieldValue('address[complement]', complement);
        setFieldValue('address[district]', district);
        setFieldValue('address[city]', city);
        setFieldValue('address[state]', state);
      } catch (e) {
        setFieldValue('address[street]', '');
        setFieldValue('address[complement]', '');
        setFieldValue('address[district]', '');
        setFieldValue('address[city]', '');
        setFieldValue('address[state]', '');
      } finally {
        setIsSearching(false);
      }
    }
  };

  return (
    <>
      <Grid.Row>
        <Grid.Column width={4}>
          <label>CEP</label>
          <Field name="address[postal_code]" type="text" component={Input} fluid
            maxLength={8} onBlur={handleZipcodeSearch} />
          <Error name="address[postal_code]" component="div" />
        </Grid.Column>
        <Grid.Column width={10}>
          <label>Logradouro</label>
          <Field name="address[street]" type="text" component={Input} fluid
            {...(isSearching && { loading: true })} />
          <Error name="address[street]" component="div" />
        </Grid.Column>
        <Grid.Column width={2}>
          <label>Número</label>
          <Field name="address[number]" type="text" component={Input} fluid
            {...(isSearching && { loading: true })} />
          <Error name="address[number]" component="div" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <label>Bairro</label>
          <Field name="address[district]" type="text" component={Input} fluid
            {...(isSearching && { loading: true })} />
          <Error name="address[district]" component="div" />
        </Grid.Column>
        <Grid.Column width={4}>
          <label>Complemento</label>
          <Field name="address[complement]" type="text" component={Input} fluid
            {...(isSearching && { loading: true })} />
          <Error name="address[complement]" component="div" />
        </Grid.Column>
        <Grid.Column width={4}>
          <label>Cidade</label>
          <Field name="address[city]" type="text" component={Input} fluid
            {...(isSearching && { loading: true })} />
          <Error name="address[city]" component="div" />
        </Grid.Column>
        <Grid.Column width={2}>
          <label>UF</label>
          <Field name="address[state]" type="text" component={Dropdown} options={states} fluid
           {...(isSearching && { loading: true })} />
          <Error name="address[state]" component="div" />
        </Grid.Column>
      </Grid.Row>
    </>
  )
};

export default AddressForm;
