import { request } from '../request';
import axios from 'axios';

export const doRequest = async (endpoint, method, headers, data) => {
  let response;

  const url = `${process.env.REACT_APP_PARCELA_API_URL}${endpoint}`;

  try {
    response = await request(url, method, headers, data);
  } catch (e) {
    throw Object.assign({}, e, response);
  }

  return response;
};

export const doRequestPayParcela = async (endpoint, method, headers, data) => {
  let response;
  
  const url = `${process.env.REACT_APP_PARCELA_API_URL_PAY}${endpoint}`;

  try {
    response = await request(url, method, headers, data);
  } catch (e) {
    throw Object.assign({}, e, response);
  }

  return response;
};

export const doRequestDocument = async (endpoint, headers, data) => {

  const baseURL = `${process.env.REACT_APP_PARCELA_API_URL}`;
  const api = axios.create({ baseURL, headers });

  let response;

  api.post(endpoint, data)
    .then(response => {
      return response;
    })
    .catch((e) => {
      throw Object.assign({}, e, response);
    });

  return response;
};
